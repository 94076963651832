import React from 'react';
import ReactPlayer from 'react-player';
import './InfoPanel.css';
import { ReactComponent as CloseIcon } from './Resources/close.svg';

const InfoPanel = ({ isVisible, onClose }) => {
  return (
    <div className={`info-panel ${isVisible ? 'visible' : ''}`}>
      <h1 className='titulo-info'>Todo lo que necesitas saber antes de empezar</h1>
      <h4>1. Define el tamaño de página desde el inicio - EASY GRID te permite seleccionar entre los formatos estándar o ingresar un tamaño personalizado.</h4>
      <h4>2. Ten clara la interlinea deseada - Este parámetro es clave para que EASY GRID calcule la grilla ideal.</h4>
      <h4>3. Estima los márgenes aproximados - No necesitas valores exactos, con tener una idea general basta para que EASY GRID haga el resto.</h4>
      <h4>4. ¡Y listo! Con esos tres datos podemos crear automáticamente la mejor grilla para tu proyecto.</h4>
      <div className='separation-line'></div>
      <div>
        <h2>Mira el siguiente tutorial de como usar la página:</h2>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=4t8ohHx1VKs"
          controls
          width="480px"
          height="315px"
        />
      </div>
      <button className="close-button" onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default InfoPanel;
