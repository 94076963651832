import React from 'react';
import "./HoverComponent.css"

const HoverComponent = ({ titulo, image, contenido }) => {
  return (
    <div className="hover-element">
        <div>
          <img src= {image} alt=""/>
          <div className='separation-line'></div>
          <h2>{titulo}</h2>
          <p>{contenido}</p>
        </div>
    </div>
  );
};
export default HoverComponent;
