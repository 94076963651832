import MainRectangle from './mainRectangle';


function App() {
  return (
    <div className="App">
      <MainRectangle />
    </div>
  );
}

export default App;
