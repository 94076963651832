import React, { useState } from 'react';
import './SettingsPanel.css';
import OnSvg from './Resources/On.svg'
import OffSvg from './Resources/Off.svg'
import Google from './Resources/GoogleLogo.svg'
import { ReactComponent as CloseIcon } from './Resources/close.svg';

const SettingsPanel = ({ isVisible, onClose, onUnitOfMeasureChange, onMostrarAyudasChange, onScopeChange }) => {
  const [medidaSeleccionada, setMedidaSeleccionada] = useState('pt');
  const [idiomaSeleccionada, setidiomaSeleccionada] = useState('es');
  const [mostrarAyudas, setMostrarAyudas] = useState(true);

  const handleMedidaChange = (event) => {
    const selectedUnit = event.target.value;
    setMedidaSeleccionada(selectedUnit);
    onUnitOfMeasureChange(selectedUnit); 
  };

  const handleIdiomaChange = (event) => {
    const selectedUnit = event.target.value;
    setidiomaSeleccionada(selectedUnit);
  };
  
  const handleToggleAyudas = () => {
    let valor = !mostrarAyudas;
    setMostrarAyudas(valor);
    onMostrarAyudasChange(valor);
  };


  const handleContinuarConGoogle = () => {alert("PROXIMAMENTE....")};

  return (
    <div className={`settings-panel ${isVisible ? 'visible' : ''}`}>
      <h1 className='titulo'>Ajustes</h1>
      <div className='separation-line-1'></div>
      <div className='idioma'>
        <label>Idioma</label>
        <select className='selector-idioma' value={idiomaSeleccionada} onChange={handleIdiomaChange}>
          <option value="esp">Español</option>
          {}
        </select>
      </div>
      <div className='separation-line-2'></div>
      {}
      <div className='unidad-medida'>
        <label className='seleccionar-medida'>Unidad de medida</label>
        <select className='selector' value={medidaSeleccionada} onChange={handleMedidaChange}>
          <option value="pt">Puntos</option>
          {}
        </select>
      </div>

      <div className='separation-line-3'></div>
      
      <div className='ayudas'>
        <label>Mostrar ayudas</label>
        <div><button className="Ayudas-Toggle" onClick={handleToggleAyudas}>{mostrarAyudas ? <img src={OnSvg} alt="On" /> : <img src={OffSvg} alt="Off" />}</button></div>
        <p>Al dejar posado el mouse en los botones, aparecerá un cartel mostrando que función cumple</p>
      </div>

      <div className='SCOPE'>
        <label>Alcance</label>
        <input 
                placeholder='15'
        />
        <p>Personaliza el rango de cálculos de módulos hasta 99 según tus necesidades.</p>
      </div>
      
      <div className='separation-line-4'></div>
      
      <div className='iniciar-sesion'>
        <label>Iniciar Sesion</label>
        <p>Para guardar datos de grillas en tu cuenta para no tener que volver a crearlas.</p>
        <button onClick={handleContinuarConGoogle}>
          <div className='google-rectangle'>
            <label>Continuar con Google</label>
            <img className='google-logo' src={Google} alt="Google" />
          </div>
        </button>
      </div>
      <div className='separation-line-5'></div>

      <div className='contacto'>
        Contacto 
        <label>01binarystudio@gmail.com</label>
      </div>
      
      <div className='version'>Versión 0.0.1</div>
      <button className="close-button" onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default SettingsPanel;
