import { toast } from 'sonner'

class mainLogic {

  lineasDisponibles;
  listaModulos;
  unidadMedida;
  pageFormats;
  margenAgregar;
  scope;
  constructor({
    selectedCategory,
    selectedIndex,
    selectedOrientation,
    interlineadoValue,
    margenSuperiorValue,
    margenInferiorValue,
    selectedSupInf,
    porcentajeSuperior,
    porcentajeInferior,
    pageFormats,
    unidadMedida,
    anchoPersonalizado,
    altoPersonalizado,
  }) {
    this.selectedCategory = selectedCategory;
    this.selectedIndex = selectedIndex;
    this.selectedOrientation = selectedOrientation;
    this.interlineadoValue = interlineadoValue;
    this.margenSuperiorValue = margenSuperiorValue;
    this.margenInferiorValue = margenInferiorValue;
    this.selectedSupInf = selectedSupInf;
    this.porcentajeInferior = porcentajeInferior;
    this.porcentajeSuperior = porcentajeSuperior;
    this.pageFormats = pageFormats;
    this.unidadMedida = unidadMedida; 
    this.anchoPersonalizado = anchoPersonalizado;
    this.altoPersonalizado = altoPersonalizado;
    this.scope = 15;
    this.cantLineasUtilizables();
    this.lineasDisponibles = this.getCantLineasUtilizables();
    this.listaModulos = this.computarModulos();
    this.computarMargenAAgregar();
    this.margenAgregar = this.getMargenAAgregar();
  }

    setUnidadMedida(newUnidadMedida) {
      this.unidadMedida = newUnidadMedida;
    }

    setScope(e){
      this.scope = e;
    }

    getMargenAAgregar(){
      if(this.margenAgregar === "0.00") 
        return "0";
      if(this.interlineadoValue > 0)
        return this.margenAgregar;
      else
        return "0";
    }

    getFormatName = (selectedIndex, selectedCategory) => {
      if(this.selectedCategory !== 'OTRO'){
        const format = this.pageFormats[selectedCategory][selectedIndex];
        return format ? format.name + selectedIndex : '';
      }
    };    

    getUnidadMedida(){
      return this.unidadMedida;
    }

    cmToPoints(cm) {
      var puntosPorCm = 28.3465;
      var puntos = cm * puntosPorCm;
      return puntos;
    }

    getAltoPagina(){
      if(this.selectedCategory !== 'OTRO'){
        return this.selectedOrientation === 'Vertical'
            ? this.pageFormats[this.selectedCategory][this.selectedIndex].width
            : this.pageFormats[this.selectedCategory][this.selectedIndex].height
      }
      else{
        let altura = 0;
        this.selectedOrientation === 'Vertical'
          ? altura = this.altoPersonalizado
          : altura = this.anchoPersonalizado

        switch(this.unidadMedida){
          case "pt" : return Math.round(this.mmToPuntos(altura)); 
          case "in" : return Math.round(this.mmToPulgadas(altura)); 
          case "cm" : return Math.round(this.mmToCentimetros(altura)); 
          default : return altura;
        }
      }
    }

  
    mmToMm(a){
      return a;
    }

    mmToPuntos(a){
      return a*2.83465;
    }

    mmToPulgadas(a){
      return a*0.0393701;
    }

    mmToCentimetros(a){
      return a*0.1;
    }
    
    getDecimalesSobrantes() {
      let resultado;
      var redondeoAbajo = Math.floor(this.getAltoPagina());
      resultado = this.getAltoPagina() - redondeoAbajo;
      resultado = Math.round(resultado * 100) / 100;
      
      return resultado;
    }
    
    getMedidaExactaSuperior(){
      let resultado;
      if(parseFloat(this.margenSuperiorValue) !== 0 && this.margenSuperiorValue !== ''){
        if (this.selectedSupInf === "Superior") {
          resultado = parseFloat(this.margenSuperiorValue) + this.getDecimalesSobrantes();
        } else {
          resultado = parseFloat(this.margenSuperiorValue);
        }
        return parseFloat(resultado.toFixed(2));
      }
      else
        return 0;
    }

    getMedidaExactaInferior(){
      let resultado;
      if(parseFloat(this.margenInferiorValue) !== 0 && this.margenInferiorValue !== ''){
        if (this.selectedSupInf === "Inferior") {
          resultado = parseFloat(this.margenInferiorValue) + this.getDecimalesSobrantes();
        } else {
          resultado = parseFloat(this.margenInferiorValue);
        }
        return parseFloat(resultado.toFixed(2));
      }
      else{
        return 0;
      }
    }

    setMargenSuperior(value) {
      let valor = parseFloat(value);
      if(valor >= 0)
        this.margenSuperiorValue = parseFloat(valor.toFixed(2));
    }

    setMargenInferior(value){
      let valor = parseFloat(value);
      if(valor >= 0)
        this.margenInferiorValue = parseFloat(valor.toFixed(2));
    }

    getPuntosDisponibles(){
      let altoPagina = 0;
      altoPagina = (this.getAltoPagina());
      const medidaInferior = (this.getMedidaExactaInferior());
      const medidaSuperior = (this.getMedidaExactaSuperior());
      const denominador = medidaInferior + medidaSuperior !== 0 ? medidaInferior + medidaSuperior : 1;
      const resultado = altoPagina - denominador;

      if(this.selectedCategory === 'OTRO' && this.altoPersonalizado === "")
        return 0;
  
      return resultado;
    }

    cantLineasUtilizables(){
      if(!isNaN(this.getPuntosDisponibles()) && !isNaN(parseFloat(this.interlineadoValue))){
        this.lineasDisponibles = this.getPuntosDisponibles() / parseFloat(this.interlineadoValue);
      }
      else
        this.lineasDisponibles = 0;
    }

    getCantLineasUtilizables(){
      if(this.lineasDisponibles === Infinity || this.lineasDisponibles === -Infinity || isNaN(this.lineasDisponibles))
        return 0;
      else
        return this.lineasDisponibles;
    }

    sumarLinea() {
      let nuevoMargenSuperior = parseFloat(this.margenSuperiorValue) + -(this.computarSuperior());
      let nuevoMargenInferior = parseFloat(this.margenInferiorValue) + -(this.computarInferior());
      if(this.margenSuperiorValue >= 0 && this.margenInferiorValue >= 0){
        if(nuevoMargenInferior > 0 && nuevoMargenSuperior > 0){
            if(this.interlineadoValue > 0){
              this.lineasDisponibles += 1;
              this.setMargenSuperior(nuevoMargenSuperior);
              this.setMargenInferior(nuevoMargenInferior);
              this.actualizarListaModulos();
            }
        }
        else{
          toast("Error: No puede ser menor a 0");
        }
      }
      else{
        toast("Error: No puede ser menor a 0");
      }
    }


    getMargenSuperiorValue(){
      const value = this.margenSuperiorValue;
      let valueString = value.toString();
      return isNaN(value) ? 0 : valueString;
    }

    getMargenInferiorValue() {
      const value = this.margenInferiorValue;
      return isNaN(value) ? 0 : value;
    }
    
    restarLinea() { 
      this.lineasDisponibles -= 1;
      if(this.lineasDisponibles < 0){
        this.lineasDisponibles = 0;
      }
      this.setMargenSuperior(parseFloat(this.margenSuperiorValue) + this.computarSuperior());
      this.setMargenInferior(parseFloat(this.margenInferiorValue) + this.computarInferior());
      this.actualizarListaModulos();
    }

    computarMargenAAgregar(){
      let valorInterlineado = this.interlineadoValue === 0 || this.interlineadoValue === "";
      let valorMargenSuperior = this.margenSuperiorValue === 0 || this.margenSuperiorValue === "";
      let valorMargenInferior = this.margenInferiorValue === 0 || this.margenInferiorValue === "";
      let retornar = "0";
      if(this.interlineadoValue === 0 && this.margenInferiorValue === 0 && this.margenSuperiorValue ===0)
          retornar = "0";
      else
      if(valorInterlineado === false && valorMargenSuperior === false && valorMargenInferior === true)
            retornar = (this.getPuntosDisponibles() - (Math.floor(this.getCantLineasUtilizables()) * this.interlineadoValue)).toFixed(2);
      else
        if(valorInterlineado === true && valorMargenSuperior === false && valorMargenInferior === false)
            retornar = (this.getPuntosDisponibles() - (Math.floor(this.getCantLineasUtilizables()) * this.interlineadoValue)).toFixed(2);
      else
          if(valorInterlineado === true && valorMargenSuperior === false && valorMargenInferior === false)
            retornar = (this.getPuntosDisponibles() - (Math.floor(this.getCantLineasUtilizables()) * this.interlineadoValue)).toFixed(2);
      else
          if(valorInterlineado === false && valorMargenSuperior === false && valorMargenInferior === false)
            retornar = (this.getPuntosDisponibles() - (Math.floor(this.getCantLineasUtilizables()) * this.interlineadoValue)).toFixed(2);
      this.margenAgregar = retornar;
    }


    computarSuperior(){
      let superior = Math.floor(this.interlineadoValue*this.porcentajeSuperior) / 100;
      return superior;
    }

    computarInferior() {
      let inferior = (this.computarSuperior()) - this.interlineadoValue ;
      return -inferior;
    }

    actualizarListaModulos() {
      const cantPruebas = this.scope;
      const lista = [];
      let lineasDisponibles = Math.floor(this.getCantLineasUtilizables());
      for (let i = 2; i < cantPruebas; i++) {
        let tamanioModulo = ((lineasDisponibles - (i - 1)) / i);
        let cantModulo = i;
        if(this.esEntero(tamanioModulo)){
          if(tamanioModulo !== 0)
            lista.push(cantModulo + " " + tamanioModulo + this.unidadMedida);
        }
      }
      this.listaModulos = lista; 
    }


    computarMargenAAgregarAuto(){
      if(this.margenAgregar <= 999){
        let valorAgregarSuperior = (this.porcentajeSuperior/100) * this.margenAgregar;
        let valorAgregarInferior = (this.porcentajeInferior/100) * this.margenAgregar;
        this.setMargenSuperior(parseFloat(this.margenSuperiorValue) + valorAgregarSuperior); 
        this.setMargenInferior(parseFloat(this.margenInferiorValue) + valorAgregarInferior);
        this.margenAgregar = 0;
      }
    }
   
    computarModulos() {
      this.actualizarListaModulos(); 
      return this.listaModulos;
    }

    getListaModulos(){
        return this.listaModulos;
    }

    cantidadModulos(){
      return this.listaModulos.length;
    }

    esEntero(numero) {
      return numero % 1 === 0;
    }

}

export default mainLogic;