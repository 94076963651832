import React, { useState, useEffect } from 'react';
import "./mainRectangle.css";
import MainLogic from './MainLogic';
import SettingsPanel from './SettingsPanel';
import helpIcon from './Resources/help.svg';
import infoIcon from './Resources/info.svg';
import userIcon from './Resources/user.svg';
import EASYGRIDLogo from './Resources/EASYGR1DLogo.svg';
import settingsIcon from './Resources/settings.svg';
import selected from './Resources/selected.svg'
import notSelected from './Resources/notSelected.svg'
import MasSVG from './Resources/mas.svg';
import MenosSVG from './Resources/menos.svg';
import arrowSVG from './Resources/arrow.svg';
import InfoPanel from './InfoPanel';
import HoverComponent from './HoverComponent';
import { Toaster, toast } from 'sonner'

const MainRectangle = () => {
  
const [selectedCategory, setSelectedCategory] =  useState("DIN A");
const [selectedIndex, setSelectedIndex] = useState(0);
const [selectedOrientation, setSelectedOrientation] = useState("Vertical");
const [interlineadoValue, setInterlineadoValue] = useState(0.00);
const [anteriorInterlineadoValue, setAnteriorInterlineadoValue] = useState(0);
const [margenSuperiorValue, setMargenSuperiorValue] = useState(0.00);
const [anteriorMargenSuperiorValue, setAnteriorMargenSuperiorValue] = useState(0);
const [margenInferiorValue, setMargenInferiorValue] = useState(0.00);
const [anteriorMargenInferiorValue, setAnteriorMargenInferiorValue] = useState(0);
const [selectedSupInf, setSelectedSupInf] = useState("Superior");
const [porcentajeSuperior, setPorcentajeSuperior] = useState(50);
const [porcentajeInferior, setPorcentajeInferior] = useState(50);
const [isPanelVisible, setPanelVisible] = useState(false);
const [isSettingsVisible, setSettingsVisible] = useState(false);
const [isHelpVisible, setHelpVisible] = useState(false);
const [isInfoVisible, setInfoVisible] = useState(false);
const [isUserVisible, setUserVisible] = useState(false);
const [unitOfMeasure, setUnitOfMeasure] = useState('pt'); 
const [mostrarAyudas, setMostrarAyudas] = useState(true); 
const [pageFormats, setPageFormats] = useState(require(`./.json files/${unitOfMeasure}-PageFormatsData.json`));
const [listaModulos, setListaModulos] = useState([]);
const [lineasDisponibles, setLineasDisponibles] = useState("");
const [anchoPersonalizado, setAnchoPersonalizado] = useState("");
const [altoPersonalizado, setAltoPersonalizado] = useState("");


const [mainLogicInstance, setMainLogicInstance] = useState(new MainLogic({
  selectedCategory,
  selectedIndex,
  selectedOrientation,
  interlineadoValue,
  margenSuperiorValue,
  margenInferiorValue,
  selectedSupInf,
  porcentajeSuperior,
  porcentajeInferior,
  unidadMedida: unitOfMeasure, 
  pageFormats: pageFormats, 
  anchoPersonalizado,
  altoPersonalizado,
  SettingsPanel,
}));

useEffect(() => {
  const newInstance = new MainLogic({
    selectedCategory,
    selectedIndex,
    selectedOrientation,
    interlineadoValue,
    margenSuperiorValue,
    margenInferiorValue,
    selectedSupInf,
    porcentajeSuperior,
    porcentajeInferior,
    unidadMedida: unitOfMeasure, 
    pageFormats: pageFormats, 
    anchoPersonalizado,
    altoPersonalizado,
  });
  setMainLogicInstance(newInstance);
}, [selectedCategory, selectedIndex, selectedOrientation, interlineadoValue, margenSuperiorValue, 
    margenInferiorValue, selectedSupInf, porcentajeSuperior, porcentajeInferior, unitOfMeasure, pageFormats,anchoPersonalizado,altoPersonalizado]);


useEffect(() => {
  setLineasDisponibles(mainLogicInstance.getCantLineasUtilizables().toFixed(2));
  setListaModulos(mainLogicInstance.getListaModulos());
}, [mainLogicInstance]);

const handleUnitOfMeasureChange = (unit) => {
  setUnitOfMeasure(unit);
  const pageFormatsData = require(`./.json files/${unit}-PageFormatsData.json`);
  setPageFormats(pageFormatsData);
  const newMainLogicInstance = new MainLogic({ ...mainLogicInstance, unidadMedida: unit,pageFormats: pageFormatsData });
  setMainLogicInstance(newMainLogicInstance);
};

const handleMostrarAyudasChange = () => {
  let valor = !mostrarAyudas;
  setMostrarAyudas(valor);
}

const getNombreUnidadMedida = () => {
    var mapeo = {
      "cm" : "Centímetros disp.:",
      "pt" : "Puntos disponibles:",
      "mm" : "Milímetros disp.:",
      "in" : "Pulgadas disponibles:" 
    }
    return mapeo[unitOfMeasure];
};

const OvalButton = ({ label, isSelected, onClick }) => {
  return (
    <button
      className={`oval-button ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(label)}
    >
      <span className="oval-button-text">{label}</span>
    </button>
  );
};

const AOvalButton = ({ name, index, isSelected, onClick }) => {
  return (
    <button
      className={`a-oval-button ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(index)}
    >
      <span className="a-oval-button-text">{name}</span>
    </button>
  );
};

const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedIndex(0);
};

const handleOptionClick = (index) => {
  setSelectedIndex(index);
};

const handleOrientationChange = (event) => {
  setSelectedOrientation(event.target.value);
};

const handleInterlineadoChange = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.slice(0, 5); 
  if (!isNaN(inputValue)) {
    setInterlineadoValue(inputValue);
    setAnteriorInterlineadoValue(inputValue);
  }
};

const handleInterlineadoClick = () => {
  setInterlineadoValue("");
};

const handlePorcentajeSuperiorClick = () => {
  setPorcentajeSuperior("");
};

const handlePorcentajeInferiorClick = () => {
  setPorcentajeInferior("");
};

const handleMargenSuperiorChange = (event) => {
  let inputValue = event.target.value;
  const regex = /^\d{0,3}(\.\d{0,2})?$/;
    if (regex.test(inputValue)) {
      setMargenSuperiorValue(inputValue);
      setAnteriorMargenSuperiorValue(inputValue);
    }

};

const handleMargenSuperiorBlur = () => {
  if (margenSuperiorValue.trim() === '') {
    setMargenSuperiorValue(anteriorMargenSuperiorValue);
  }
};

const handleMargenInferiorBlur = () => {
  if (margenInferiorValue.trim() === '') {
    setMargenInferiorValue(anteriorMargenInferiorValue);
  }
};

const handleInterlineadoBlur = () => {
  if (interlineadoValue.trim() === '') {
    setInterlineadoValue(anteriorInterlineadoValue);
  }
};

const handleMargenSuperiorClick = (event) => {
  setMargenSuperiorValue("");
};

const handleMargenInferiorChange = (event) => {
  let inputValue = event.target.value;
  const regex = /^\d{0,3}(\.\d{0,2})?$/;
  if (regex.test(inputValue)) {
    setMargenInferiorValue(inputValue);
    setAnteriorMargenInferiorValue(inputValue);
  }
};

const handleMargenInferiorClick = () => {
  setMargenInferiorValue('');
};

const handleSuperiorInferiorChange = (event) => {
  setSelectedSupInf(event.target.value);
};

const handlePorcentajeSuperiorChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setPorcentajeSuperior(newValue);
      setPorcentajeInferior(100 - newValue);
    }
};

const handlePorcentajeInferiorChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setPorcentajeInferior(newValue);
      setPorcentajeSuperior(100 - newValue);
    }
};

const getFormatName = (selectedIndex, selectedCategory) => {
  if(selectedCategory !== 'OTRO'){
    const format = pageFormats[selectedCategory][selectedIndex];
    return format ? format.name + selectedIndex : '';
  }
  else
    return "CM"

};

const handleSettingsClick = () => {
  setSettingsVisible(!isSettingsVisible);
  setPanelVisible(!isPanelVisible);
};

const handleHelpClick = () => {
  setHelpVisible(!isHelpVisible);
  setPanelVisible(!isPanelVisible);
};

const handleInfoClick = () => {
  setInfoVisible(!isInfoVisible);
  //setPanelVisible(!isPanelVisible);
  alert("PROXIMAMENTE...");
};

const handleUserClick = () => {
  setUserVisible(!isUserVisible);
  //setPanelVisible(!isPanelVisible);
  alert("PROXIMAMENTE...");
};
const handleSumarLinea = () => {
  mainLogicInstance.sumarLinea();
  const nuevasLineasDisponibles = mainLogicInstance.getCantLineasUtilizables().toFixed(2);
  setLineasDisponibles(nuevasLineasDisponibles);
  setListaModulos(mainLogicInstance.getListaModulos());
};

const handleRestarLinea = () => {
  mainLogicInstance.restarLinea();
  const nuevasLineasDisponibles = mainLogicInstance.getCantLineasUtilizables().toFixed(2);
  setLineasDisponibles(nuevasLineasDisponibles);
  setListaModulos(mainLogicInstance.getListaModulos());
};

const handleMargenAAgregar = () => {
  if(mainLogicInstance.getMargenAAgregar() <= 999){
    mainLogicInstance.computarMargenAAgregarAuto();
    setMainLogicInstance(new MainLogic({...mainLogicInstance}));
  }
};

const handleMargenSuperiorExactoClick = () => {
  const contenido = mainLogicInstance.getMedidaExactaSuperior();
  navigator.clipboard.writeText(contenido)
  .catch((error) => {console.error('Error al copiar al portapapeles: ', error); });
  toast('Copiado al portapapeles!');
}

const handleMargenInferiorExactoClick = () => {
  const contenido = mainLogicInstance.getMedidaExactaInferior();
  navigator.clipboard.writeText(contenido)
    .catch((error) => {console.error('Error al copiar al portapapeles: ', error); });
  toast('Copiado al portapapeles!');
}

const handleScopeChange = (e) => {
  mainLogicInstance.setScope(e);
}

return (
  <div className={`wireframe ${isPanelVisible ? 'panel-visible' : ''}`}>
    <div className={`rectangle-parent ${isPanelVisible ? 'panel-visible' : ''}`}>
        <div className="body">
        <div className="div-1">
          <div className="din">
            {['DIN A','DIN B','DIN C','DIN D','OTRO'].map((category, index) => (
              <OvalButton
                key={index}
                label={category}
                isSelected={selectedCategory === category}
                onClick={handleCategoryClick}
              />
            ))}
          </div>

          {selectedCategory !== "OTRO" ? (
            <div className="a">
              {Object.keys(pageFormats[selectedCategory] || {}).map((name, index) => (
                <AOvalButton
                  key={index}
                  name={getFormatName(index, selectedCategory)}                  
                  index={index}
                  isSelected={selectedIndex === index}
                  onClick={handleOptionClick}
                />
              ))}
            </div>
          ) : (
            <div className='a'>
            <label>
              Personalizado: 
              <input className='ancho'
                type="number"
                value={anchoPersonalizado}
                placeholder='ancho'
                onChange={(e) => setAnchoPersonalizado(e.target.value)}
              />
            </label>
            x
            <label>
              <input className='alto'
                type="number"
                placeholder='alto'
                value={altoPersonalizado}
                onChange={(e) => setAltoPersonalizado(e.target.value)}
              />
            </label>
              mm
          </div>
          )}
          </div>
          <div className="div-2">

          <div className="orientation-selector">
              <div className={`svg-container ${selectedOrientation === 'Vertical' ? 'selected' : ''}`} onClick={() => handleOrientationChange({ target: { value: 'Vertical' } })}>
              <img src={selectedOrientation === 'Vertical' ? selected : notSelected} 
                  alt={selectedOrientation === 'Vertical' ? 'Selected' : 'Not Selected'} width="14" height="15" />
                <span className="selection-vh-label">Vertical</span>
              </div>

              <div className={`svg-container ${selectedOrientation === 'Horizontal' ? 'selected' : ''}`} onClick={() => handleOrientationChange({ target: { value: 'Horizontal' } })}>
              <img src={selectedOrientation === 'Horizontal' ? selected : notSelected} 
                  alt={selectedOrientation === 'Horizontal' ? 'Selected' : 'Not Selected'} width="14" height="15" />
                <span className="selection-vh-label">Horizontal</span>
              </div>
          </div>

            <div className="alto-pagina">
              <span>Alto página: </span>
              {mainLogicInstance.getAltoPagina()} {unitOfMeasure}
            </div>
          </div>
        </div>
        <div className="frame-item" />

        <div className="header">
          <img 
            className="info-icon" 
            alt="" 
            src={infoIcon} 
            onClick={handleInfoClick}
          />
          <img 
            className="help-icon" 
            alt="" 
            src={helpIcon} 
            onClick={handleHelpClick}
          />
          <img
            className="easy-grid"
            alt=""
            src={EASYGRIDLogo}
          />
          <img
            className="user-icon"
            alt=""
            src={userIcon}
            onClick={handleUserClick}
          />
          <img
            className="settings-icon"
            alt=""
            src={settingsIcon}
            onClick={handleSettingsClick}
          />

        </div>
        <div className="div2-izquierda">
        <div className="interlineado">Interlineado</div>
          <input
            type="text"
            value={interlineadoValue}
            onChange={handleInterlineadoChange}
            onBlur={handleInterlineadoBlur}
            onClick={handleInterlineadoClick}
            className="interlineado-input"
          />
          <span className="interlineado-unit">pt</span>
          <div className='interlineado-separation-line'></div>


        <div className="margen-superior">Margen superior</div>
          <input
            type="text"
            value={(mainLogicInstance.getMargenSuperiorValue())}
            onChange={handleMargenSuperiorChange}
            onBlur={handleMargenSuperiorBlur}
            onClick={handleMargenSuperiorClick}
            className="margen-superior-input"
          />
          <span className="margen-superior-unit">{unitOfMeasure}</span>
          <img className="arrow-1" alt="" src={arrowSVG} />
          <div className='margen-superior-valor-exacto'onClick={handleMargenSuperiorExactoClick} >{mainLogicInstance.getMedidaExactaSuperior()}</div>
          <div className='margen-superior-separation-line'></div>
        

        <div className="margen-inferior">Margen inferior</div>
          <input
            type="text"
            value={mainLogicInstance.getMargenInferiorValue()}
            onChange={handleMargenInferiorChange}
            onBlur={handleMargenInferiorBlur}
            onClick={handleMargenInferiorClick}
            className="margen-inferior-input"
          />
          <span className="margen-inferior-unit">{unitOfMeasure}</span>
          <img className="arrow-2" alt="" src={arrowSVG} />
          <div className='margen-inferior-valor-exacto'onClick={handleMargenInferiorExactoClick}>{mainLogicInstance.getMedidaExactaInferior()}</div>
          <div className='margen-inferior-separation-line'></div>
        
        <div className='lineas-disponibles'>Lineas: 
            <span className='disponibles'> Disponibles: </span>
            <span className='cant-lineas-disp'>{lineasDisponibles}</span>
            <div className='boton-mas-menos-lineas'>
              <button className='boton-mas' onClick={() => handleSumarLinea()}>
                <img src={MasSVG} alt='Más' />
              </button>
              <button className='boton-menos' onClick={() => handleRestarLinea()}>
                <img src={MenosSVG} alt='Menos' />
              </button>
            </div>
        </div>        
        </div>
        <table className="rounded-table">
          <thead>
            <tr>
              <th colSpan="2" className="pt-margen-container">
                <span className="pt-margen-a" style={{ color: mainLogicInstance.getMargenAAgregar() !== "0" ? '#FF0000' : 'black' }}>Ajuste de márgenes
                  {mostrarAyudas && <HoverComponent
                     image={require('./HoverGif/AjusteMargen.gif')}
                    contenido ="Se encarga de calcular y aplicar el retoque justo a los márgenes para que tu grilla quede perfecta, sin necesidad de pasar tiempo haciendo ajustes manuales."
                  />}
                </span>
                <div onClick={() => handleMargenAAgregar()} className="valor-pt-margen-a">
                  <p style={{ color: mainLogicInstance.getMargenAAgregar() !== "0" ? '#FF0000' : 'black' }}>
                    {mainLogicInstance.getMargenAAgregar()}
                  </p>
                </div>
              </th>
              <th colSpan="2" className="modulos-ok-container">
                <span className="modulos-ok" onClick={() => handleMargenAAgregar()} style={{ color: mainLogicInstance.getMargenAAgregar() !== "0" ? '#FF0000' : 'black' }}>Módulos recomendados</span>
                <p className="valor-modulos-ok" onClick={() => handleMargenAAgregar()} style={{ color: mainLogicInstance.getMargenAAgregar() !== "0" ? '#FF0000' : 'black' }}>{mainLogicInstance.cantidadModulos()}</p>
              </th>
            </tr>
            <tr className='nombre-columnas'>
              <th className='table-column-name-iz'>Cant. Modulos</th>
              <th className='table-column-name-de'>Tamaño</th>
              <th className='separation-line-table'></th>
            </tr>
          </thead>
          <tbody>
            {mainLogicInstance.getMargenAAgregar() === "0" && interlineadoValue > 0 && listaModulos.map((modulo, index) => {
              const [primerValor, segundoValor] = modulo.split(' ');
              return (
                <tr key={index} className={index % 2 === 0 ? 'fila-par' : 'fila-impar'}>
                  <td className="primer-valor">{primerValor}</td>
                  <td className="segundo-valor">{segundoValor}</td>
                </tr>
              );
            })}
          </tbody>
      </table>

      <div className='div3'>
        <div className='opciones-avanzadas'>Opciones avanzadas:
        <h2 className='sobrante'>Decimales restantes: </h2>
        <h2 className='sobrante-num'>{mainLogicInstance.getDecimalesSobrantes()}</h2>
        <div className="superior-inferior">
              <div className={`svg-container ${selectedSupInf === 'Superior' ? 'selected' : ''}`} onClick={
                () => handleSuperiorInferiorChange({ target: { value: 'Superior' } })}>
                <img src={selectedSupInf === 'Superior' ? selected : notSelected} alt={selectedSupInf === 'Superior' ? 'Selected' : 'Not Selected'} width="14" height="15" />
                <span className="selection-vh-label">Superior</span>
              </div>

              <div className={`svg-container ${selectedSupInf === 'Inferior' ? 'selected' : ''}`} onClick={
                () => handleSuperiorInferiorChange({ target: { value: 'Inferior' } })}>
                <img src={selectedSupInf === 'Inferior' ? selected : notSelected} alt={selectedSupInf === 'Inferior' ? 'Selected' : 'Not Selected'} width="14" height="15" />
                <span className="selection-vh-label">Inferior</span>
              </div>
            </div>
        </div>

        <div className='porcentaje-inferior-superior'>
          <span className='porcentaje-text'>% al (+) o (-) lineas:</span>
          <div className='porcentaje-superior'>
            Superior:
            <input
              type="number"
              value={porcentajeSuperior}
              onChange={handlePorcentajeSuperiorChange}
              onClick={handlePorcentajeSuperiorClick}
              placeholder="50"
              className="porcentaje-superior-input"
            />
          </div>
          <p className='signo-porcentaje-1'>%</p>
          <div className='porcentaje-separation-line-1'></div>
          <div className='porcentaje-inferior'>
            Inferior:
            <input
              type="number"
              value={porcentajeInferior}
              onChange={handlePorcentajeInferiorChange}
              onClick={handlePorcentajeInferiorClick}
              placeholder="50"
              className="porcentaje-inferior-input"
            />
          </div>
          <p className='signo-porcentaje-2'>%</p>
          <div className='porcentaje-separation-line-2'></div>
        </div>
      </div>

      <div className='div4'>Informacion final 
          <h2 className='h2-1'>Hoja {getFormatName(selectedIndex,selectedCategory)}   - {selectedOrientation}</h2>  
          <h2 className='h2-2'>Mar. Superior: {(mainLogicInstance.getMedidaExactaSuperior())}</h2>
          <h2 className='h2-3'>Líneas disponibles: {mainLogicInstance.getCantLineasUtilizables().toFixed(2)}</h2>
          <h2 className='h2-4'>Interlineado: {interlineadoValue}</h2>
          <h2 className='h2-5'>Mar. Inferior: {(mainLogicInstance.getMedidaExactaInferior())}</h2>     
          <h2 className='h2-6'>{getNombreUnidadMedida()} {mainLogicInstance.getPuntosDisponibles().toFixed(2)}</h2>
      </div>
      
      </div>  
      <SettingsPanel
        isVisible = {isSettingsVisible}
        onClose = {handleSettingsClick}
        onUnitOfMeasureChange = {handleUnitOfMeasureChange}
        onMostrarAyudasChange = {handleMostrarAyudasChange}
        onScopeChange = {handleScopeChange}
      />

      <InfoPanel
        isVisible={isHelpVisible}
        onClose={handleHelpClick}
      />
      <Toaster position='bottom-center' toastOptions={{duration: 3000,className: 'my-toast'}} />
    </div>
  );
};

export default MainRectangle;
